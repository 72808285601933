import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';

import { Link, useNavigate } from 'react-router-dom';

import { getItem, clearStorage } from '../../../utils/localStorage';
function Header() {
  const navigate = useNavigate()
  const [userName, setFullname] = useState("")
  const [userEmail, setEmail] = useState("")
  const [visibleChangePassword, setVisibleChangePassword] = useState(false)
  
  const getCurrentUser = async () => {
    try {
      const currentUser = await getItem('COREUSER')
      if (currentUser) {
        const fullName = currentUser.user.fullName
        const email = currentUser.user.email
        setFullname(fullName)
        setEmail(email)
      }

    }
    catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    getCurrentUser()

  }, [])
  return (

    <header className="pc-header">
      <div className="header-wrapper">

        <div className="me-auto pc-mob-drp">
          <ul className="list-unstyled">
            <li className="pc-h-item pc-sidebar-collapse">
              <Link to={"#"} className="pc-head-link ms-0" id="sidebar-hide">
                <i className="ti ti-menu-2"></i>
              </Link>
            </li>
            <li className="pc-h-item pc-sidebar-popup">
              <Link to='#' className="pc-head-link ms-0 mobile-collapse">
                <i className="ti ti-menu-2"></i>
              </Link>
            </li>
          </ul>
        </div>
        <div className="ms-auto">
          <ul className="list-unstyled">

         


            <li className="dropdown pc-h-item header-user-profile">
              <Link
                className="pc-head-link dropdown-toggle arrow-none me-0"
                data-bs-toggle="dropdown"
                to={"#"}
                role="button"
                aria-haspopup="false"
                data-bs-auto-close="outside"
                aria-expanded="false"
              >
                <img src="/assets/images/user/avatar-2.jpg" alt="user-image" className="user-avtar" />
              </Link>
              <div className="dropdown-menu dropdown-user-profile dropdown-menu-end pc-h-dropdown">
                <div className="dropdown-header d-flex align-items-center justify-content-between">
                  <h5 className="m-0">Profile</h5>
                </div>
                <div className="dropdown-body">
                  <div className="profile-notification-scroll position-relative" style={{ maxHeight: `calc(100vh - 225px)` }}>
                    <div className="d-flex mb-1">
                      <div className="flex-shrink-0">
                        <img src="/assets/images/user/avatar-2.jpg" alt="user-image" className="user-avtar wid-35" />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h6 className="mb-1">{userName}</h6>
                        <span>{userEmail}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <Dialog header="Change Password" visible={visibleChangePassword} style={{ width: '50vw' }} onHide={() => { if (!visibleChangePassword) return; setVisibleChangePassword(false); }}>
        <form>
          <div className="form-group">
            <label>Current Password:</label>
            <input className="form-control mt-2" type="password" required />
          </div>
          <div className="form-group mt-2">
            <label>New Password:</label>
            <input className="form-control mt-2" type="password" required />
          </div>
          <div className="form-group mt-2">
            <label>Confirm Password:</label>
            <input className="form-control mt-2" type="password" required />
          </div>
          <div className="text-end mt-4">
            <button type="submit" className="btn btn-outline-secondary" >
              Clear
            </button>
            &nbsp;
            <button type="submit" className="btn btn-primary" >
              Submit
            </button>
          </div>
        </form>
      </Dialog>
    </header>

  )
}
export default Header