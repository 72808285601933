import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Link, useNavigate } from 'react-router-dom';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { makeRequest2 } from '../../../utils/makeRequest';
import { toastify } from '../../../utils/toast';
import { getFacilitiesURL, addFacilityURL } from '../../../utils/urls'; // Importing addFacilityURL if needed
import Layout from '../component/layout';
import { filterObjectsByAllProperties } from '../../../utils/filterSearch';

function Facilities() {
    const [facilities, setFacilities] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [currentFacility, setCurrentFacility] = useState('');
    const [currentsubDivision, setSubDivision] = useState('');
    const [visibleDivision, setVisibleDivision] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    const getFacilities = async () => {
        try {
            const response = await makeRequest2(getFacilitiesURL, 'GET', {});
    
            if (response.success) {
                setFacilities(response.data);
            }
        } catch (error) {
            toastify(error.message, 'error');
        }
    };

    const handleInputSearch = async (value) => {
        try {
            setSearchTerm(value);
            if (value === '') {
                getFacilities();
            } else {
                const results = await filterObjectsByAllProperties(facilities, value);
                setFacilities(results);
            }
        } catch (error) {
            toastify(error.message, 'error');
        }
    };

    const isEnabledBodyTemplate = (rowData) => {
     
        return rowData.isEnabled ? (
            <span className="badge rounded-pill text-bg-success">Enabled</span>
        ) : (
            <span className="badge rounded-pill text-bg-danger">Disabled</span>
        );
    };

    const divisionsTemplate = (rowData) => {
        return (
            <ul className="list-inline me-auto mb-0">
                <li className="list-inline-item align-bottom" data-bs-toggle="tooltip" title="View">
                    <Link
                        to={'#'}
                        className="avtar avtar-xs btn-link-secondary btn-pc-default"
                        onClick={() => {
                            setVisibleDivision(true);
                            setDivisions(rowData.divisionArray);
                            console.log("DIVISION", rowData.divisionArray);
                            setCurrentFacility(rowData.name);
                            setSubDivision(rowData.subDivision);
                            console.log("SUBDIVISION", rowData.subDivision);
                        }}
                    >
                        <i className="ti ti-eye f-18"></i>
                    </Link>
                </li>
            </ul>
        );
    };
    const nameTemplate = (rowData) => {
        return (<Link to={'/core/facilities/view_facility/' + rowData._id}>
            <strong>{rowData.name}</strong>
        </Link>)
    }
    useEffect(() => {
        getFacilities();
    }, []);



    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/core/dashboard"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/core/facilities"}>Facilities </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="form-search">
                                                <i className="ti ti-search"></i>
                                                <input type="search" className="form-control" placeholder="Search Here" value={searchTerm} onChange={(e) => {
                                                    handleInputSearch(e.target.value)
                                                }} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="dt-responsive table-responsive">
                                        <DataTable value={facilities} emptyMessage="No facilities found." sortMode="multiple" paginator rows={10} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                            <Column field="name" sortable header="Name" body={nameTemplate}></Column>

                                            <Column field="location" header="Location"></Column>
                                            <Column field="subDivision" header="Sub Division"></Column>
                                            <Column field="subDivision" body={divisionsTemplate} header="Divisions"></Column>
                                            <Column field="isEnabled" body={isEnabledBodyTemplate} header="Is Enabled"></Column>
                                        </DataTable>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog header={currentFacility} visible={visibleDivision} style={{ width: '30vw' }} onHide={() => { setDivisions([]); setVisibleDivision(false); }}>
                <table className="table">
                    <thead>
                        <tr>
                            <th>{currentsubDivision}(s)</th>
                            <th>Floors</th>
                        </tr>
                    </thead>
                    <tbody>
                        {divisions && divisions.length > 0 ? (
                            divisions.map((division) => (
                                <tr key={division.divisionName}>
                                    <td>{division.divisionName}</td>
                                    <td>{division.NoOfFloors}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="2">No divisions available</td>
                            </tr>
                        )}
                    </tbody>

                </table>
            </Dialog>
        </Layout>
    );
}

export default Facilities;
