import React, { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom'
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { makeRequest2 } from '../../../utils/makeRequest'
import { toastify } from '../../../utils/toast'
import { getCompaniesURL } from '../../../utils/urls'
import Layout from '../component/layout'
import { filterObjectsByAllProperties } from '../../../utils/filterSearch';

function Companies() {
  const [companies, setCompanies] = useState([])
  const [filterCompanies, setFilteredCompanies] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  const getCompanies = async () => {
    try {
      const response = await makeRequest2(getCompaniesURL, 'GET', {})

      if (response.success) {
        setCompanies(response.data)
        setFilteredCompanies(response.data)
      }
    } catch (error) {
      toastify(error.message, 'error')
    }
  }

  const handleInputSearch = async (value) => {
    try {
      setSearchTerm(value)
      if (value === '') {
        setFilteredCompanies(companies);
      } else {
        const results = await filterObjectsByAllProperties(companies, value);
        setFilteredCompanies(results)
      }
    } catch (error) {
      toastify(error.message, 'error')
    }
  }

  const handleFilter = async (value, filter) => {
    try {
      if (filter === 'All') {
        setFilteredCompanies(companies);
      } else if (filter === 'Enabled') {
        const results = companies.filter((x) => x.isEnabled === true)
        setFilteredCompanies(results)
      } else if (filter === 'Disabled') {
        const results = companies.filter((x) => x.isEnabled === false)
        setFilteredCompanies(results)
      }
    } catch (error) {
      toastify(error.message, 'error')
    }
  }

  const sitesBodyTemplate = (rowData) => {
    return (
      <span className="badge rounded-pill text-bg-info">{rowData.facilities.length}</span>
    )
  };

  const isEnabledBodyTemplate = (rowData) => {
    return (
      <span className={`badge rounded-pill ${rowData.isEnabled ? 'text-bg-success' : 'text-bg-danger'}`}>
        {rowData.isEnabled ? 'Enabled' : 'Disabled'}
      </span>
    )
  };

  const actionsTemplate = (rowData) => {
    return (
      <ul className="list-inline me-auto mb-0">
        <li className="list-inline-item align-bottom" data-bs-toggle="tooltip" title="View">
          <Link
            to={`/core/companies/view_company/` + rowData._id}
            className="avtar avtar-xs btn-link-secondary btn-pc-default"
          >
            <i className="ti ti-eye f-18"></i>
          </Link>
        </li>
      </ul>
    )
  }

  useEffect(() => {
    getCompanies()
  }, [])

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/core/dashboard"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/core/companies"}>Business Partners</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-4">
                      <div className="row">
                        <div className="col-9">
                         
                            <div className="form-search">
                                                <i className="ti ti-search"></i>
                                                <input type="search" className="form-control" placeholder="Search Here" value={searchTerm} onChange={(e) => {
                                                    handleInputSearch(e.target.value)
                                                }} />
                                            </div>
                        </div>
                        <div className="col-3">
                          <div className="btn-group-dropdown" style={{ float: 'right' }}>
                            <button
                              className="btn btn-outline-default dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="ti ti-filter"></i>
                            </button>
                            <div className="dropdown-menu">
                              <Link className="dropdown-item" to="#!" onClick={() => handleFilter('', 'All')}>
                                All
                              </Link>
                              <Link className="dropdown-item" to="#!" onClick={() => handleFilter(true, 'Enabled')}>
                                Enabled
                              </Link>
                              <Link className="dropdown-item" to="#!" onClick={() => handleFilter(false, 'Disabled')}>
                                Disabled
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-8">
                      <Link
                        to={"/core/companies/add_company"}
                        type="button"
                        className="btn btn-primary float-end"
                        style={{ color: 'white' }}
                      >
                        <i className="ti ti-plus" />
                        &nbsp;Create a New Business Partner
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <div className="dt-responsive table-responsive">
                    <DataTable
                      value={filterCompanies}
                      emptyMessage="No business partners found."
                      sortMode="multiple"
                      paginator
                      rows={5}
                      stripedRows
                      tableStyle={{ minWidth: '50rem' }}
                    >
                      <Column field="name" sortable header="Name"></Column>
                      <Column header="Facilities" body={sitesBodyTemplate}></Column>
                      <Column header="Status" body={isEnabledBodyTemplate}></Column>
                      <Column header="Actions" body={actionsTemplate}></Column>
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Companies
