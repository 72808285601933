import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import Layout from '../component/layout'
import { makeRequest2 } from '../../../utils/makeRequest';
import { getDashboardDataURL } from '../../../utils/urls';
import { toastify } from '../../../utils/toast';

function Dashboard() {
  const [companies, setCompanies] = useState(0);
  const [facilities, setFacilities] = useState(0);
  const [customers, setCustomers] = useState(0);


  const getDashboardData = async () => {
    try {
      const response = await makeRequest2(getDashboardDataURL, 'GET', {})
      if (response.success) {
        setCompanies(response.data.companies)
        setFacilities(response.data.facilities);
        setCustomers(response.data.customers)
      }
      else {
        throw new Error(response.error)
      }
    }
    catch (err) {
      toastify(err.message, 'error')
    }
  }

  useEffect(() => {
    getDashboardData()
  }, [])

  return (
    <Layout>
      <div className="row">
        <div className="col-12">

        </div>
        <div className="col-md-6 col-xxl-3">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <div className="avtar avtar-s bg-light-primary">
                    <span className="pc-micon">
                      <svg className="pc-icon">
                        <use xlinkHref="#custom-data" />
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="flex-grow-1 ms-3">
                  <h6 className="mb-0">Business Partners</h6>
                </div>

              </div>
              <div className="bg-body p-3 mt-3 rounded">
                <div className="mt-3 row align-items-center">

                  <div className="col-5">
                    <h5 className="mb-1">{companies}</h5>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xxl-3">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <div className="avtar avtar-s bg-light-warning">
                    <span className="pc-micon">
                      <svg className="pc-icon">
                        <use xlinkHref="#custom-add-item" />
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="flex-grow-1 ms-3">
                  <h6 className="mb-0">Facilities</h6>
                </div>

              </div>
              <div className="bg-body p-3 mt-3 rounded">
                <div className="mt-3 row align-items-center">

                  <div className="col-5">
                    <h5 className="mb-1">{facilities}</h5>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xxl-3">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <div className="avtar avtar-s bg-light-success">
                    <span className="pc-micon">
                      <svg className="pc-icon">
                        <use xlinkHref="#custom-profile-2user-outline" />
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="flex-grow-1 ms-3">
                  <h6 className="mb-0">Customers</h6>
                </div>
                <div className="flex-shrink-0 ms-3">

                </div>
              </div>
              <div className="bg-body p-3 mt-3 rounded">
                <div className="mt-3 row align-items-center">

                  <div className="col-5">
                    <h5 className="mb-1">{customers}</h5>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>





        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <h5 className="mb-0">Total Income</h5>

              </div>
              <div id="total-income-graph" />
              <div className="row g-3 mt-3">
                <div className="col-md-12 text-center">
                  <strong>No Data Found</strong>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>

    </Layout>

  )
}
export default Dashboard